import React from 'react';


function Loader() {
  return (
    <lottie-player
      src="https://lottie.host/fe9812e6-0ee8-4584-8dbb-dfbbf09fcd7e/ouJNEkQ8te.json"
      background="transparent"
      speed="1"
      style={{ width: '300px', height: '300px' }}
      loop
      autoplay
    ></lottie-player>
  );
}

export default Loader;
