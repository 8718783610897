import React, { lazy, Suspense } from 'react';
import { Route, Routes } from "react-router-dom"
import Loader from './components/Home/Loader';

// pages
const Register = lazy(() => import('./pages/Register'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const EmailVerification = lazy(() => import('./pages/EmailVerify'));
const EmailNotify = lazy(() => import('./pages/EmailNotify'));
const Onboarding = lazy(() => import('./pages/Onboarding'));
const LoginAdmin = lazy(() => import('./pages/LoginAdmin'));
const Swarocare = lazy(() => import('./pages/Swarocare'));
const Testimonials = lazy(() => import('./components/Testimonials/Testimonials'));

// public routes
const PublicRoutes = lazy(() => import('./utils/PublicRoutes'));

// protected routes
const PatientProtectedRoutes = lazy(() => import('./utils/PatientProtectedRoutes'));
const DoctorProtectedRoutes = lazy(() => import('./utils/DoctorProtectedRoutes'));
const AdminProtectedRoutes = lazy(() => import('./utils/AdminProtectedRoutes'));
const AdminDashboardLayout = lazy(() => import('./utils/AdminDashboardLayout'));
const DoctorDashboardLayout = lazy(() => import('./utils/DoctorDashboardLayout'));

// components which are parts of layouts
const AdminDoctorsTable = lazy(() => import('./components/AdminDoctorsTable/DoctorsTableAdmin'));
const AdminPatientsTable = lazy(() => import('./components/AdminPatientsTable/AdminPatientsTable'));
const AdminTransactionsTable = lazy(() => import('./components/AdminTransactionsTable/AdminTransactionsTable'));
const AdminSpecialitiesTable = lazy(() => import('./components/AdminSpeciatiesTable/AdminSpecialitiesTable'));
const AdminCharts = lazy(() => import('./components/AdminCharts/AdminCharts'));
const AdminAppointmentsTable = lazy(() => import('./components/AdminAppointmentsTable/AdminAppointmentsTable'));
const DoctorPatientsTable = lazy(() => import('./components/DoctorPatientsTable/DoctorPatientsTable'));
const DoctorAppointmentsTable = lazy(() => import('./components/DoctorAppointmentsTable/DoctorAppointmentsTable'));
const DoctorNotifications = lazy(() => import('./components/DoctorNotifications/DoctorNotifications'));
const DoctorMessages = lazy(() => import('./components/DoctorMessages/DoctorMessages'));
const DoctorSchedulings = lazy(() => import('./components/DoctorSchedulings/DoctorSchedulings'));
const DoctorCharts = lazy(() => import('./components/DoctorCharts/DoctorCharts'));
const PatientDashboardLayout = lazy(() => import('./utils/PatientDashboardLayout'));
const PatientDoctorsTable = lazy(() => import('./components/PatientDoctorsTable/PatientDoctorsTable'));
const PatientAppointmentTable = lazy(() => import('./components/PateinetAppointmentsTable/PatientAppointmentTable'));
const PatientNotifications = lazy(() => import('./components/PatientNotifications/PatientNotifications'));
const PatientMessages = lazy(() => import('./components/PatientMessages/PatientMessages'));
const ViewDoctors = lazy(() => import('./components/ViewDoctors/ViewDoctors'));
const AvailableSlots = lazy(() => import('./pages/AvailableSlots'));
const Checkout = lazy(() => import('./pages/Checkout'));
const BookingSuccess = lazy(() => import('./pages/BookingSuccess'));
const AdminNotifications = lazy(() => import('./components/AdminNotifications/AdminNotifications'));
const VideoCall = lazy(() => import('./components/VideoCall/VideoCall'));
const NotFoundPage = lazy(() => import('./components/NotFoundPage/NotFoundPage'));
const DoctorProfileSettings = lazy(() => import('./components/DoctorProfileSettings/DoctorProfileSettings'));
const QualityCare = lazy(() => import('./components/QualityCare/Qualitycare'));
const PaymentHistory = lazy(() => import('./components/PaymentHistory/PaymentHistory'));

function AppRoutes() {
  return (
    <Suspense fallback={<div>.</div>}>
      <Routes>

        <Route path='/' element={<Swarocare></Swarocare>}></Route>
        <Route path='/quality' element={<QualityCare></QualityCare>}></Route>
        <Route path='/doctors/all' element={<ViewDoctors></ViewDoctors>}></Route>
        <Route path='/:id/appointment' element={<AvailableSlots></AvailableSlots>}></Route>
        <Route path="/doctor/appointments/join/:id" element={<VideoCall></VideoCall>} />
        <Route path='/testimonials' element={<Testimonials></Testimonials>}></Route>

        {/* Public routes */}
        <Route element={<PublicRoutes></PublicRoutes>}>
          <Route path="/signup" element={<Register />} />
          <Route path="/email/notification" element={<EmailNotify />} />
          <Route path="/:usertype/verify/:token" element={<EmailVerification />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/forgot/password" element={<ForgotPassword />} />
          <Route path="/:userType/reset/password/:token" element={<ResetPassword />} />
          <Route path='/admin/signin' element={<LoginAdmin></LoginAdmin>}></Route>
        </Route>

        {/* Patient specfic routes */}
        <Route element={<PatientProtectedRoutes></PatientProtectedRoutes>}>
          <Route path="/patient/onboarding" element={<Onboarding role='patient' />} />
          <Route path='/doctor/:id' element={<AvailableSlots />}></Route>
          <Route path='/doctor/:id/checkout' element={<Checkout />}></Route>
          <Route path='/appointment/:id/success' element={<BookingSuccess />}></Route>
          <Route path='/patient' element={<PatientDashboardLayout />}>
            <Route path='dashboard' element={<PatientDoctorsTable />}></Route>
            <Route path='appointments' element={<PatientAppointmentTable />}></Route>
            <Route path='notifications' element={<PatientNotifications />}></Route>
            <Route path='messages' element={<PatientMessages />}></Route>
            <Route path='history' element={<PaymentHistory></PaymentHistory>}></Route>
            
          </Route>
        </Route>


        {/* Doctor specific routes */}
        <Route element={<DoctorProtectedRoutes></DoctorProtectedRoutes>}>
          <Route path="/doctor/onboarding" element={<Onboarding role='doctor' />} />

          <Route path='/doctor' element={<DoctorDashboardLayout></DoctorDashboardLayout>}>
            <Route path='dashboard' element={<DoctorCharts></DoctorCharts>}></Route>
            <Route path='mypatients' element={<DoctorPatientsTable></DoctorPatientsTable>}></Route>
            <Route path='appointments' element={<DoctorAppointmentsTable />}></Route>
            <Route path='notifications' element={<DoctorNotifications />}></Route>
            <Route path='messagges' element={<DoctorMessages />}></Route>
            <Route path='schedulings' element={<DoctorSchedulings />}></Route>
            <Route path='profile' element={<DoctorProfileSettings />}></Route>
          </Route>

        </Route>

        {/* Admin related routes */}
        <Route element={<AdminProtectedRoutes></AdminProtectedRoutes>}>
          <Route path='/admin' element={<AdminDashboardLayout></AdminDashboardLayout>}>
            <Route path='dashboard' element={<AdminCharts></AdminCharts>}></Route>
            <Route path='doctors' element={<AdminDoctorsTable></AdminDoctorsTable>}></Route>
            <Route path='patients' element={<AdminPatientsTable></AdminPatientsTable>}></Route>
            <Route path='appointments' element={<AdminAppointmentsTable></AdminAppointmentsTable>}></Route>
            <Route path='transactions' element={<AdminTransactionsTable></AdminTransactionsTable>}></Route>
            <Route path='specialities'
              element={<AdminSpecialitiesTable></AdminSpecialitiesTable>}>
            </Route>
            <Route path='notifications'
              element={<AdminNotifications></AdminNotifications>}>
            </Route>
          </Route>
        </Route>

        <Route path='*' element={<NotFoundPage></NotFoundPage>}></Route>

      </Routes>
    </Suspense>
  )
}

export default AppRoutes